import { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useContextAuth } from 'src/context/AuthContext';
import { analyticsService } from 'src/service';
import InputCaptcha, {
  InputCaptchaRef,
  isCaptchaValid,
} from 'src/components/atoms/form/InputCaptcha';
import { ErrorMessage } from 'src/components/organisms/forms';
import useBusiness from 'src/hooks/useBusiness';
import {
  Button,
  InputText,
  InputPasswordMask,
  schemaPasswword as schemaPassword,
  LabelForm,
} from '@blum-rivendell/reactui';
import AmplitudeAnalytics from 'src/reports/analytics/AmplitudeAnalytics';
import { publicRoutes } from 'src/routes';
import CustomLink from 'src/components/atoms/CustomLink';
import Link from 'next/link';

const ANALYTICS_ACTION = '/security/signin';

type SignInFormInputs = {
  email: string;
  password: string;
};

const schemaSignIn = yup
  .object({
    email: yup
      .string()
      .required('El email es requerido')
      .transform((v) => (v ? v.trim() : v))
      .email('El email no es válido'),
    password: schemaPassword,
  })
  .required();

export type SignInFormPropsI = {
  defaultEmail?: string;
  onSuccessSignIn: (data: any) => void;
  onUnconfirmedError: (email: string) => void;
};

export const SignInForm = ({
  defaultEmail = '',
  onSuccessSignIn,
  onUnconfirmedError,
}: SignInFormPropsI) => {
  const { onSignIn } = useContextAuth();
  const captcha = useRef<InputCaptchaRef>(null);
  const { isLoading, run: runSignIn } = useBusiness<{
    id: string;
    name: string;
    status: string;
    nextAction: string;
    challenge?: any;
  }>({
    onSuccess: async (dataSignIn) => {
      const { email } = await getValues();
      await onSignIn(email);
      onSuccessSignIn(dataSignIn);
      if (dataSignIn?.id) {
        analyticsService.pushActionOk(ANALYTICS_ACTION);
        AmplitudeAnalytics.setUser(dataSignIn?.id);
        AmplitudeAnalytics.registerSuccessEvent('customer:login:create');
      }
    },
    onError: (e) => {
      analyticsService.pushActionError(ANALYTICS_ACTION);
      AmplitudeAnalytics.registerErrorEvent('customer:login:create', {
        message: e.message,
      });
      captcha.current?.reset();
      if (e.message === 'Correo electrónico no ha sido confirmado.') {
        const { email } = getValues();
        onUnconfirmedError(email);
      }
      setErrorMessage(e.message);
    },
  });

  useEffect(() => {
    analyticsService.pushRender(ANALYTICS_ACTION);
    AmplitudeAnalytics.registerInitEvent('customer:login:create', {
      'url-origin': document?.referrer ?? '',
    });
  }, []);

  const { handleSubmit, getValues, control } = useForm<SignInFormInputs>({
    resolver: yupResolver(schemaSignIn),
    defaultValues: {
      email: ((): string => {
        let email = undefined;
        if (typeof window !== 'undefined') {
          email = JSON.parse(localStorage?.getItem('EMAIL_OTP') || 'null');
          if (email) {
            setTimeout(() => {
              localStorage.removeItem('EMAIL_OTP');
            }, 3000);
          }
        }

        return email ?? defaultEmail;
      })(),
    },
  });

  const [errorMessage, setErrorMessage] = useState('');

  const onCaptchaError = () => setErrorMessage('Falta completar captcha');

  const onSubmit = ({ email, password }: SignInFormInputs) => {
    setErrorMessage('');

    if (!isCaptchaValid(captcha)) {
      onCaptchaError();
      return;
    }

    runSignIn((b) =>
      b.customer.signIn({
        email: email.trim(),
        password,
        captcha: captcha?.current?.getValue() || '',
      })
    );
  };

  return (
    <form
      data-testid="signin-form"
      onSubmit={handleSubmit(onSubmit)}
      className="bl-bg-white bl-w-full md:bl-w-96 bl-box-border bl-max-w-md bl-flex bl-flex-col bl-items-stretch bl-rounded-4xl bl-py-6 bl-px-3 md:bl-py-8 sm:bl-px-6"
    >
      <p className="bl-text-2xl bl-font-bold bl-text-primary-green-90 bl-text-center bl-mb-4 md:bl-mb-7">
        ¿Estás de vuelta? <br /> Inicia sesión
      </p>
      <div className="bl-mb-3">
        <Controller
          name="email"
          control={control}
          render={({ field: { ref, ...field }, fieldState }) => (
            <LabelForm
              label="Correo electrónico"
              error={fieldState.error?.message}
              isCompleted={!!field.value}
              errorTestId="error-email"
              isDisabled={isLoading}
            >
              <InputText
                disabled={isLoading}
                hasError={!!fieldState.error}
                data-testid="email-input"
                type="text"
                {...field}
              />
            </LabelForm>
          )}
        />
      </div>
      <div className="bl-mb-3">
        <Controller
          name="password"
          control={control}
          render={({ field: { ref, ...field }, fieldState }) => (
            <LabelForm
              label="Contraseña"
              error={fieldState.error?.message}
              isCompleted={!!field.value}
              errorTestId="error-password"
              isDisabled={isLoading}
            >
              <InputPasswordMask
                data-testid="password-input"
                disabled={isLoading}
                hasError={!!fieldState.error}
                {...field}
              />
            </LabelForm>
          )}
        />
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <div className="bl-text-sm bl-text-center bl-text-neutral-dark-80 bl-mb-4">
        ¿Olvidaste tu contraseña?{' '}
        <CustomLink
          href={publicRoutes.forgotPassword.path}
          data-testid="forgotPasswordLink"
        >
          <b>Recupérala aquí</b>
        </CustomLink>
      </div>
      <InputCaptcha captchaRef={captcha} onError={onCaptchaError} />
      <div className="bl-self-center bl-my-3">
        <Button
          type="submit"
          text="Iniciar sesión"
          aria-label="Iniciar sesión"
          testId="submit-signin"
          isLoading={isLoading}
        />
      </div>
      <p className="bl-text-center bl-text-sm bl-text-neutral-dark-80">
        ¿No tienes una cuenta? {''}
        <Link
          href={publicRoutes.createCustomer.path}
          data-testid="customer-creation-link"
          className="bl-text-secondary-blue-50 bl-font-medium bl-underline"
        >
          ¡Regístrate!
        </Link>
      </p>
    </form>
  );
};

export default SignInForm;
